'use client';
import { createContext, useContext, useState } from 'react';

import { enableStaticRendering, Provider } from 'mobx-react';
import { QueryClientProvider } from '@tanstack/react-query';

// Stores
import orderStore from '~/store/Orders/Cart';
import paymentsStore from '~/store/Payments/MpCard';
import sessionStore from '~/store/Sessions/Sessions';
import accountStore from '~/store/Account/Account';
import catalogStore from '~/store/Catalog/Catalog';
import facets from '~/store/Facets/Facets';
import modals from '~/store/Modals/Modals';
import navigationStore from '~/store/Navigation/Navigation';
import { queryClient } from '~/store/Query/api';

const isServer = typeof window === 'undefined';
enableStaticRendering(isServer);

const stores = {
  orderStore,
  paymentsStore,
  sessionStore,
  accountStore,
  catalogStore,
  facets,
  modals,
  navigationStore,
};

const QueryContext = createContext(undefined);

export function QueryProvider({ children }) {
  const [queryString, setQueryString] = useState('');

  return <QueryContext.Provider value={{ queryString, setQueryString }}>{children}</QueryContext.Provider>;
}

export function useQueryString() {
  return useContext(QueryContext);
}

const Context = createContext(undefined);

export function PageContextProvider({ pageContext, children }) {
  return (
    <Context.Provider value={pageContext}>
      <Provider {...stores}>
        <QueryProvider>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </QueryProvider>
      </Provider>
    </Context.Provider>
  );
}

export function usePageContext() {
  return useContext(Context);
}
